<template>
    <div class="py-1">
        <b-container class="border round">
            <b-row
                class="bg-light-secondary justify-content-between align-items-center header p-75 shadow-sm shadow-bottom"
            >
                <div>
                    <span class="font-weight-bolder font-medium-2"
                        >Asunto:
                    </span>
                    <span class="tres-puntos font-medium-2">
                        Algo para decirle al administrador
                    </span>
                </div>
                <!-- <b-button
                    variant="gradient-success"
                    pill
                    class="p-50 mr-1"
                    v-b-tooltip.hover.top="''"
                >
                    <feather-icon icon="CheckIcon" size="20"></feather-icon>
                </b-button> -->
                <div>
                    <b-button
                        variant="gradient-primary"
                        pill
                        class="p-50"
                        v-b-tooltip.hover.top="'Cerrar soporte'"
                    >
                        <feather-icon
                            icon="ArchiveIcon"
                            size="20"
                        ></feather-icon>
                    </b-button>
                    <b-dropdown variant="link" right no-caret>
                        <template v-slot:button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                            ></feather-icon>
                        </template>
                        <b-dropdown-item @click="abrirModalVerPerfil">
                            <feather-icon icon="EyeIcon"></feather-icon>
                            Ver perfil
                        </b-dropdown-item>
                        <b-dropdown-item> </b-dropdown-item>
                    </b-dropdown>
                </div>
            </b-row>
            <b-row>
                <b-col cols="4" class="border-right">
                    <div class="text-center mt-2 position-relative">
                        <b-avatar
                            variant="light-secondary"
                            size="120"
                            class="my-auto mx-auto"
                        >
                        </b-avatar>
                    </div>
                    <div class="text-center my-1">
                        <div class="indicador-online mr-50"></div>
                        <span class="text-muted"
                            >Activo(Últ. vez 12:00 pm)</span
                        >
                    </div>
                    <!-- <div class="text-center my-1">
                        <div class="indicador-offline mr-50"></div>
                        <span class="text-muted"
                            >Inactivo(Últ. vez 12:00 pm)</span
                        >
                    </div> -->
                    <p class="text-center mb-25">Ana Maria Morales Morales</p>
                    <p class="text-center font-weight-bolder">Nueva eps</p>
                    <div class="w-100 text-center my-1">
                        <b-button
                            variant="outline-primary"
                            size="sm"
                            @click="abrirModalVerPerfil"
                            >Ver perfil
                        </b-button>
                    </div>
                    <div
                        class="d-flex justify-content-start align-items-center"
                    >
                        <div class="border rounded mr-1">
                            <feather-icon
                                icon="PhoneIcon"
                                size="20"
                                class="text-primary m-50"
                            ></feather-icon>
                        </div>
                        <span>+57 3213545622</span>
                    </div>
                    <div
                        class="d-flex justify-content-start align-items-center my-1"
                    >
                        <div class="border rounded mr-1">
                            <feather-icon
                                icon="MailIcon"
                                size="20"
                                class="text-primary m-50"
                            ></feather-icon>
                        </div>
                        <span>ana@gmail.com</span>
                    </div>
                    <div class="row justify-content-center flex-nowrap my-1">
                        <div
                            v-for="(indicador, index) in indicadores"
                            :key="index"
                            class="indicadores text-center mx-1 p-1"
                        >
                            <p
                                :class="`font-weight-bolder font-medium-4 mb-0 text-${indicador.color}`"
                            >
                                {{ indicador.numero }}
                            </p>
                            <p class="font-small-1">{{ indicador.nombre }}</p>
                        </div>
                    </div>
                </b-col>
                <b-col>
                    <!-- aquí va el chat -->
                    <b-row
                        class="overflow-auto flex-column-reverse"
                        style="height: calc(100vh - 40vh)"
                    >
                        <b-col class="bg-light-secondary p-1">
                            <div
                                v-for="(mensaje, index) in mensajes"
                                :key="index"
                            >
                                <!-- chat izquierda -->
                                <div
                                    v-if="!mensaje.mio"
                                    class="d-flex flex-nowrap animate__animated animate__bounceIn"
                                >
                                    <b-avatar
                                        variant="light-secondary"
                                    ></b-avatar>
                                    <span
                                        class="position-relative text-white ml-1"
                                        >◀</span
                                    >
                                    <div class="card shadow-sm p-50 mr-4">
                                        <p class="mb-0">
                                            {{ mensaje.mensaje }}
                                        </p>
                                        <small class="font-small-1">
                                            {{ mensaje.fecha }}
                                        </small>
                                    </div>
                                </div>

                                <!-- chat derecha(mío) -->
                                <div
                                    v-else
                                    class="d-flex flex-nowrap justify-content-end animate__animated animate__bounceIn"
                                >
                                    <div class="card shadow-sm p-50 ml-4">
                                        <p class="mb-0">
                                            {{ mensaje.mensaje }}
                                        </p>
                                        <small class="font-small-1">
                                            {{ mensaje.fecha }}
                                        </small>
                                    </div>
                                    <span
                                        class="position-relative text-white mr-1"
                                        >▶</span
                                    >
                                    <b-avatar
                                        variant="light-primary"
                                    ></b-avatar>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <!-- aquí termina el chat -->
                    <b-row>
                        <div class="col-11 p-25">
                            <b-form-input
                                v-model="inputMensaje"
                                size="lg"
                                @keyup.enter="addMensaje"
                            ></b-form-input>
                        </div>
                        <b-button
                            variant="transparent"
                            pill
                            class="col-1 p-75"
                            @click="addMensaje"
                        >
                            <feather-icon
                                icon="SendIcon"
                                size="20"
                                class="text-success rotate-45"
                            ></feather-icon>
                        </b-button>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
        <!-- modal ver perfil paciente -->
        <modal-ver-perfil ref="refmodalVerPerfil"></modal-ver-perfil>
    </div>
</template>
<script>
import { ref } from "@vue/composition-api";
export default {
    components: {
        modalVerPerfil: () => import("./modals/ModalVerPerfilPaciente.vue"),
    },
    setup(props, context) {
        const indicadores = ref([
            {
                nombre: "Soportes",
                numero: 22,
                color: "primary",
            },
            {
                nombre: "Asistencias",
                numero: 12,
                color: "dark",
            },
            {
                nombre: "Dispositivos",
                numero: 23,
                color: "dark",
            },
        ]);

        const inputMensaje = ref("");

        const mensajes = ref([
            {
                mio: 0,
                mensaje:
                    "hola, mi nombre es Ana María y quisiera solicitar capacitación sobre el sistema, gracias",
                fecha: "10:30pm",
            },
        ]);

        function abrirModalVerPerfil() {
            context.refs.refmodalVerPerfil.toggle();
        }

        function addMensaje() {
            if (!inputMensaje.value.length)
                return context.root.notificacion(
                    "Por favor escribe un mensaje valido",
                    "AlertTriangleIcon",
                    "warning"
                );
            mensajes.value.push({
                mio: 1,
                mensaje: inputMensaje.value,
                fecha: "20:20pm",
            });

            inputMensaje.value = "";
        }

        return {
            indicadores,
            inputMensaje,
            addMensaje,
            mensajes,
            abrirModalVerPerfil,
        };
    },
};
</script>
<style lang="css" scope>
.header {
    border-radius: 20px 20px 0px 0px;
}
.indicador-online {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: lime;
    border-radius: 50%;
}
.indicador-offline {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: gray;
    border-radius: 50%;
}
.indicadores {
    height: 4em;
    width: 6em;
}
.mensaje {
}
</style>
